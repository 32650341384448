import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchHeights(context) {
            return getRequests('/heights', 'updateHeights', context)
        },
        fetchDeletedHeights(context) {
            return getRequests('/heights-deleted', 'updateDeletedHeights', context)
        },
        deleteHeights(context, heights) {
            return postRequest('/heights/delete', heights, 'updateHeight', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreHeights(context, heights) {
            return postRequest('/heights/restore', heights, 'updateHeight', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
        pushHeight(context, height) {
            return postRequest('/heights', height, 'updateHeight', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
    },
    mutations: {
        updateHeights(state, heights) {
            state.heights = heights
        },
        updateDeletedHeights(state, deletedHeights) {
            state.deletedHeights = deletedHeights
        },
        updateHeight() {},
    },
    state: {
        heights: {
            models: [],
            totalItems: 0
        },
        deletedHeights: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getHeights(state) {
            return state.heights.models
        },
        getDeletedHeights(state) {
            return state.deletedHeights.models
        },
    }
}
