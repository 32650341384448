import getRequests from "@/plugins/requests/getRequests"

export default {
    actions: {
        fetchRoles(context){
            return getRequests('/roles', 'updateRoles', context)
        },
    },

    mutations: {
        updateRoles(state, roles) {
            state.roles = roles
        },
    },

    state: {
        roles: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getRoles(state) {
            return state.roles.models
        },
    }
}
