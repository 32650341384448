import {createRouter, createWebHistory} from "vue-router/dist/vue-router"
import store from "@/plugins/vuex/store"
import accessMixin from "@/mixins/accessMixin";

const ifNotAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next('/')
    } else {
        next()
    }
}

const ifAuthorized = (to, from, next) => {
    if (store.getters.isAuthorized) {
        next()
    } else {
        next('/login')
    }
}

const accessAddEmployee = (to, from, next) => {
    if (store.getters.getUser.roles.length === 0) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (accessMixin.computed.combinationOne()) {
                    next()
                } else {
                    next('/')
                }
            })
            .catch(() => {
                next('/')
            });
    } else {
        next()
    }
}

const accessFinance = (to, from, next) => {
    if (store.getters.getUser.roles.length === 0) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (accessMixin.computed.combinationTwo()) {
                    next()
                } else {
                    next('/')
                }
            })
            .catch(() => {
                next('/')
            });
    } else {
        next()
    }
}

const accessWarehouse = (to, from, next) => {
    if (store.getters.getUser.roles.length === 0) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (accessMixin.computed.combinationThree()) {
                    next()
                } else {
                    next('/')
                }
            })
            .catch(() => {
                next('/')
            });
    } else {
        next()
    }
}

const accessEmployeeList = (to, from, next) => {
    if (store.getters.getUser.roles.length === 0) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (accessMixin.computed.combinationFive()) {
                    next()
                } else {
                    next('/')
                }
            })
            .catch(() => {
                next('/')
            });
    } else {
        next()
    }
}

const accessReports = (to, from, next) => {
    if (store.getters.getUser.roles.length === 0) {
        store.dispatch('fetchAboutMe')
            .then(() => {
                if (accessMixin.computed.combinationSix()) {
                    next()
                } else {
                    next('/')
                }
            })
            .catch(() => {
                next('/')
            });
    } else {
        next()
    }
}

const routes = [
    {
        path: '/',
        component: () => import('@/pages/DashboardPage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/',
                component: () => import('@/components/dashboard/warehouse/WarehouseDashboardComponent.vue'),
            },
            {
                path: '/partners-board',
                component: () => import('@/components/dashboard/partner/PartnerDashboardComponent.vue'),
                beforeEnter: accessFinance
            },
            {
                path: '/finance-board',
                component: () => import('@/components/dashboard/finance/StatisticsFinanceComponent.vue'),
                beforeEnter: accessFinance
            },
        ]
    },
    {
        path: '/login',
        component: () => import('@/pages/LoginPage.vue'),
        beforeEnter: ifNotAuthorized
    },
    {
        path: '/employees',
        component: () => import('@/pages/EmployeesPage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/employees',
                component: () => import('@/components/employees/EmployeesListComponent.vue'),
                beforeEnter: accessEmployeeList
            },
            {
                path: '/employee/:employeeId?',
                component: () => import('@/components/employees/EmployeeProfileComponent.vue'),
            },
            {
                path: '/add-employee',
                component: () => import('@/components/employees/AddEmployeeComponent.vue'),
                beforeEnter: accessAddEmployee
            },
        ]
    },
    {
        path: '/warehouses',
        component: () => import('@/pages/WarehousePage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/grouped-data',
                component: () => import('@/components/report/NomenclaturesComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/warehouses',
                component: () => import('@/components/warehouse/WarehousesComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/warehouse/:id/:page?',
                component: () => import('@/components/warehouse/WarehouseComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/income-invoices/:page?',
                component: () => import('@/components/warehouse/IncomeComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/add-income-invoice',
                component: () => import('@/components/warehouse/AddIncomeInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/edit-income-invoice/:invoiceId',
                component: () => import('@/components/warehouse/EditIncomeInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/expense-invoices/:page?',
                component: () => import('@/components/warehouse/ExpenseComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/add-expense-invoice/:page?',
                component: () => import('@/components/warehouse/AddExpenseInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/edit-expense-invoice/:invoiceId/:page?',
                component: () => import('@/components/warehouse/EditExpenseInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/return-invoices/:page?',
                component: () => import('@/components/warehouse/ReturnComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/transfer-invoices/:page?',
                component: () => import('@/components/warehouse/TransferComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/add-transfer-invoice/:page?',
                component: () => import('@/components/warehouse/AddTransferInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/edit-transfer-invoice/:invoiceId',
                component: () => import('@/components/warehouse/EditTransferInvoiceComponent.vue'),
                beforeEnter: accessWarehouse,
            },
            {
                path: '/defective-products/:page?',
                component: () => import('@/components/warehouse/DefectiveProducts.vue'),
                beforeEnter: accessWarehouse,
            },
        ]
    },
    {
        path: '/office-expense',
        component: () => import('@/pages/FinancePage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/office-expense/:page?',
                component: () => import('@/components/finance/office/OfficeExpenseFinanceComponent.vue'),
                beforeEnter: accessFinance
            },
            {
                path: '/firm-expense/:page?',
                component: () => import('@/components/finance/firm/FirmExpenseFinanceComponent.vue'),
                beforeEnter: accessFinance
            },
            {
                path: '/transfer/:page?',
                component: () => import('@/components/finance/transfer/TransferFinanceComponent.vue'),
                beforeEnter: accessFinance
            },
            {
                path: '/accounting/:page?',
                component: () => import('@/components/finance/accounting/AccountingFinanceComponent.vue'),
                beforeEnter: accessFinance
            },
        ]
    },
    {
        path: '/partners',
        component: () => import('@/pages/ReportPage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/partners/:page?',
                component: () => import('@/components/report/PartnersComponent.vue'),
                beforeEnter: accessReports
            },
            {
                path: '/cashiers',
                component: () => import('@/components/report/CashiersComponent.vue'),
                beforeEnter: accessFinance
            },
            {
                path: '/nomenclatures',
                component: () => import('@/components/report/NomenclaturesComponent.vue'),
                beforeEnter: accessReports
            },
            {
                path: '/calculations',
                component: () => import('@/components/report/BossesComponent.vue'),
                beforeEnter: accessFinance
            },
        ]
    },
    {
        path: '/logs',
        component: () => import('@/pages/LogsPage.vue'),
        beforeEnter: ifAuthorized,
        children: [
            {
                path: '/logs/:page?',
                component: () => import('@/components/log/LogsComponent.vue'),
                beforeEnter: accessReports
            },
        ]
    },
]

export default createRouter({
    history: createWebHistory(),
    routes
})
