import postRequest from "@/plugins/requests/postRequest";
import putRequest from "@/plugins/requests/putRequest";
import getRequests from "@/plugins/requests/getRequests";
import getRequest from "@/plugins/requests/getRequest";
import patchRequest from "@/plugins/requests/patchRequest";

export default {
    actions: {
        fetchToken(context, data) {
            return postRequest('/users/auth', data, 'updateToken', context)
        },
        refreshToken(context, data) {
            return postRequest('/users/auth/refreshToken', {refreshToken: data}, 'updateToken', context)
        },
        fetchAboutMe(context){
            return postRequest('/users/about_me', {},'updateUser', context)
        },
        fetchUsers(context){
            return getRequests('/users', 'updateUsers', context)
        },
        fetchDeletedEmployees(context){
            return getRequests('/users/deleted', 'updateUsers', context)
        },
        fetchEmployee(context, employeeId){
            return getRequest('/users/' + employeeId, 'updateEmployee', context)
        },
        pushUser(context, data) {
            return postRequest('/users', data, 'updateUser', context)
        },
        fetchIsUniqueUsername(context, data) {
            return postRequest('/users/is_unique_username', data, 'updateIsUniqueUsername', context)
        },
        patchUser(context, data) {
            return patchRequest('/users/' + data.userId, data.user, 'updateUser', context)
        },
        patchEmployee(context, data) {
            return patchRequest('/users/' + data.userId, data.user, 'updateEmployee', context)
        },
        patchUserPerson(context, data) {
            return patchRequest('/people/' + data.personId, data.person, 'updateUserPerson', context)
        },
        patchEmployeePerson(context, data) {
            return patchRequest('/people/' + data.personId, data.person, 'updateEmployeePerson', context)
        },
        changePassword(context, data) {
            return putRequest('/users/' + data.id + '/password', data.user, 'changeUser', context)
        },
        deleteEmployee(context, data) {
            return postRequest('/users/delete_user', data, 'updateEmployee', context)
        },
        restoreEmployee(context, data) {
            return postRequest('/users/restore_user', data, 'updateEmployee', context)
        },
    },

    mutations: {
        updateToken(state, tokens) {
            localStorage.setItem('accessToken', tokens.accessToken)
            localStorage.setItem('refreshToken', tokens.refreshToken)

            state.accessToken = tokens.accessToken
            state.refreshToken = tokens.refreshToken
        },
        updateUser(state, user) {
            state.user = user
        },
        updateEmployee(state, employee) {
            state.employee = employee
        },
        updateUserPerson(state, person) {
            state.user.person = person
        },
        updateEmployeePerson(state, person) {
            state.employee.person = person
        },
        updateUsers(state, users) {
            state.users = users
        },
        clearTokens(state) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            state.accessToken = localStorage.getItem('accessToken')
            state.refreshToken = localStorage.getItem('refreshToken')
            state.user = {
                "@id": '',
                id: null,
                userName: '',
                roles: [],
                isDelete: null,
                person: {
                    "@id": '',
                    id: null,
                    about: '',
                    birthday: '',
                    familyName:'',
                    givenName: '',
                    telephone: '',
                    image: null,
                },
            }
        },
        updateIsUniqueUsername(state, data) {
            state.isUniqueUsername = data.isUnique
        },
        changeUser() {},
    },

    state: {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),

        user: {
            "@id": '',
            id: null,
            userName: '',
            roles: [],
            isDelete: null,
            person: {
                "@id": '',
                id: null,
                about: '',
                birthday: '',
                familyName:'',
                givenName: '',
                telephone: '',
                image: null,
            },
        },

        employee: {
            "@id": '',
            id: null,
            userName: '',
            roles: [],
            isDelete: null,
            person: {
                "@id": '',
                id: null,
                about: '',
                birthday: '',
                familyName:'',
                givenName: '',
                telephone: '',
                image: null,
            },
        },

        users: {
            models: [],
            totalItems: 0
        },

        isUniqueUsername: null,
    },

    getters: {
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        isAuthorized(state) {
            return state.accessToken !== null
        },
        getUser(state) {
            return state.user
        },
        getEmployee(state) {
            return state.employee
        },
        getUsers(state) {
            return state.users.models
        },
        getIsUniqueUsername(state) {
            return state.isUniqueUsername
        },
        getUserRoles(state) {
            return state.user.roles
        }
    }
}
