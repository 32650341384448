import postRequest from "@/plugins/requests/postRequest";

export default {
    actions: {
        putBalance(context, data) {
            return postRequest('/balances/put', data, 'updateBalance', context)
        }
    },
    mutations: {
        updateBalance() {}
    },
}