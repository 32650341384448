import getRequests from "@/plugins/requests/getRequests";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest";

const toast = useToast()

export default {
    actions: {
        fetchTypeCoating(context) {
            return getRequests('/type_coatings', 'updateTypeCoatings', context)
        },
        fetchDeletedTypeCoatings(context) {
            return getRequests('/type_coatings-deleted', 'updateDeletedTypeCoatings', context)
        },
        deleteTypeCoatings(context, typeCoatings) {
            return postRequest('/type_coatings/delete', typeCoatings, 'updateTypeCoatings', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreTypeCoatings(context, typeCoatings) {
            return postRequest('/type_coatings/restore', typeCoatings, 'updateTypeCoating', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
        pushTypeCoating(context, partner) {
            return postRequest('/type_coatings', partner, 'updateTypeCoating', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
    },
    mutations: {
        updateTypeCoatings(state, typeCoating) {
            state.typeCoating = typeCoating
        },
        updateDeletedTypeCoatings(state, deletedTypeCoatings) {
            state.deletedTypeCoatings = deletedTypeCoatings
        },
        updateTypeCoating() {}
    },
    state: {
        typeCoating: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        },
        deletedTypeCoatings: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        }
    },
    getters: {
        getTypeCoating(state) {
            return state.typeCoating.models
        },
        getDeletedTypeCoating(state) {
            return state.deletedTypeCoatings.models
        }
    }
}
