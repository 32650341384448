import getRequests from "@/plugins/requests/getRequests";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest";

const toast = useToast()

export default {
    actions: {
        fetchFactories(context) {
            return getRequests('/factories', 'updateFactories', context)
        },
        fetchDeletedFactories(context) {
            return getRequests('/factories-deleted', 'updateDeletedFactories', context)
        },
        deleteFactories(context, factories) {
            return postRequest('/factories/delete', factories, 'updateFactory', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreFactories(context, factories) {
            return postRequest('/factories/restore', factories, 'updateFactory', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
        pushFactory(context, factory) {
            return postRequest('/factories', factory, 'updateFactory', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
    },
    mutations: {
        updateFactories(state, factories) {
            state.factories = factories
        },
        updateDeletedFactories(state, deletedFactories) {
            state.deletedFactories = deletedFactories
        },
        updateFactory() {},
    },
    state: {
        factories: {
            models: [],
            totalItems: 0
        },
        deletedFactories: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getFactories(state) {
            return state.factories.models.map(factory => {
                return {
                    ...factory,
                    name: factory.name.split(' ')[0]
                }
            })
        },
        getDeletedFactories(state) {
            return state.deletedFactories.models.map(factory => {
                return {
                    ...factory,
                    name: factory.name.split(' ')[0]
                }
            })
        },
    }
}
