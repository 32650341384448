import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchSorts(context) {
            return getRequests('/sorts', 'updateSorts', context)
        }
    },
    mutations: {
        updateSorts(state, sorts) {
            state.sorts = sorts
        }
    },
    state: {
        sorts: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getSorts(state) {
            return state.sorts.models
        }
    }
}
