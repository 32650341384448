import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import {useToast} from 'vue-toastification'
import getRequest from "@/plugins/requests/getRequest";

const toast = useToast()

export default {
    actions: {
        fetchPartners(context, filterProps = {}) {
            let partnerUrl = '';

            if (filterProps.providers.length || filterProps.customers.length) {
                partnerUrl += '&partners=' + filterProps.providers.concat(filterProps.customers);
            }

            return getRequests(`/partners?page=${filterProps.page}&items-per-page=${filterProps.itemsPerPage}&show=${filterProps.show}${partnerUrl}`, 'updatePartners', context)
        },
        fetchPartner(context, partnerId) {
            return getRequest('/partners/' + partnerId, 'updatePartner', context)
        },
        fetchTop20Partners(context) {
            return getRequest('/partners/top20', 'updateTop20', context)
        },
        pushPartner(context, partner) {
            return postRequest('/partners', partner, 'updatePartner', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        changePartner(context, partner) {
            return postRequest('/partners/update', partner, 'updatePartner', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deletePartners(context, customers) {
            return postRequest('/partners/delete', customers, 'updateDeletedPartners', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restorePartners(context, customers) {
            return postRequest('/partners/restore', customers, 'updateDeletedPartners', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },


        // ************************ PROVIDERS ************************
        fetchProviders(context) {
            return getRequests('/partners/providers', 'updateProviders', context)
        },
        fetchActiveProviders(context, filterProps = {}) {
            let partnerUrl = '';

            if (filterProps.name) {
                partnerUrl = '&name=' + filterProps.name;
            }

            return getRequests('/partners/active-providers?page=' + filterProps.page + partnerUrl, 'updateActiveProviders', context)
        },
        fetchDeletedProviders(context) {
            return getRequests('/partners/deleted-providers', 'updateDeletedProviders', context)
        },


        // ************************ CUSTOMERS ************************
        fetchCustomers(context, filterProps = {}) {
            let partnerUrl = '';

            if (filterProps.name) {
                partnerUrl += '&name=' + filterProps.name;
            }

            if (filterProps.cyrilName) {
                partnerUrl += '&cyrilName=' + filterProps.cyrilName;
            }

            return getRequests('/partners/customers?page=' + filterProps.page + partnerUrl, 'updateCustomers', context)
        },
        fetchActiveCustomers(context, filterProps = {}) {
            let partnerUrl = '';

            if (filterProps.name) {
                partnerUrl = '&name=' + filterProps.name;
            }

            return getRequests('/partners/active-customers?page=' + filterProps.page + partnerUrl, 'updateActiveCustomers', context)
        },
        fetchDeletedCustomers(context, filterProps = {}) {
            let partnerUrl = '';

            if (filterProps.name) {
                partnerUrl = '&name=' + filterProps.name;
            }

            return getRequests('/partners/deleted-customers?page=' + filterProps.page + partnerUrl, 'updateDeletedCustomers', context)
        },
    },
    mutations: {
        updatePartners(state, partners) {
            state.partners = partners
        },
        updatePartner(state, partner) {
            state.partner = partner
        },
        updateTop20(state, top20Partner) {
            state.top20Partner = top20Partner
        },
        updateDeletedPartners() {
        },

        // ************************ PROVIDERS ************************
        updateProviders(state, providers) {
            state.providers = providers
        },
        updateActiveProviders(state, activeProviders) {
            state.activeProviders = activeProviders
        },
        updateDeletedProviders(state, deletedProviders) {
            state.deletedProviders = deletedProviders
        },

        // ************************ CUSTOMERS ************************
        updateCustomers(state, customers) {
            state.customers = customers
        },
        updateActiveCustomers(state, activeCustomers) {
            state.activeCustomers = activeCustomers
        },
        updateDeletedCustomers(state, deletedCustomers) {
            state.deletedCustomers = deletedCustomers
        },
    },
    state: {
        // ************************ PROVIDERS ************************
        providers: {
            models: [],
            totalItems: 0
        },
        activeProviders: {
            models: [],
            totalItems: 0
        },

        // ************************ CUSTOMERS ************************
        customers: {
            models: [],
            totalItems: 0,
        },
        activeCustomers: {
            models: [],
            totalItems: 0,
        },
        partner: {
            id: 0,
            debt: 0,
            delimit: 0,
            calculated: 0,
            rating: 0,
            name: '',
            isCustomer: false,
            currency: {
                id: 0,
                name: ''
            }
        },
        top20Partner: {
            debts: [],
            prepays: []
        },
        deletedProviders: {
            models: [],
            totalItems: 0,
        },
        deletedCustomers: {
            models: [],
            totalItems: 0,
        },

        partners: {
            models: {
                partners: [],
                sums: {
                    debtUzs: 0,
                    debtUsd: 0,
                    prepayUzs: 0,
                    prepayUsd: 0,
                }
            },
            totalItems: 0,
            pagesCount: 0
        },
    },
    getters: {
        getPartners(state) {
            return state.partners
        },
        getTop20Partners(state) {
            return state.top20Partner
        },
        // ************************ PROVIDERS ************************
        getProviders(state) {
            return state.providers.models
        },
        getActiveProviders(state) {
            return state.activeProviders.models
        },
        getActiveProvidersCount(state) {
            return state.activeProviders.totalItems
        },

        // ************************ CUSTOMERS ************************
        getCustomers(state) {
            return state.customers.models
        },
        getCustomersCount(state) {
            return state.customers.totalItems
        },
        getActiveCustomers(state) {
            return state.activeCustomers.models
        },
        getActiveCustomersCount(state) {
            return state.activeCustomers.totalItems
        },
        getPartner(state) {
            return state.partner
        },
        getDeletedProviders(state) {
            return state.deletedProviders.models
        },
        getDeletedCustomers(state) {
            return state.deletedCustomers.models
        },
        getDeletedCustomersCount(state) {
            return state.deletedCustomers.totalItems
        },
    }
}
