import getRequests from "@/plugins/requests/getRequests";
import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchCashiers(context) {
            return getRequests('/cashiers', 'updateCashiers', context)
        },
        fetchCashier(context, cashierId) {
            return getRequest('/cashiers/' + cashierId, 'updateCashier', context)
        }
    },
    mutations: {
        updateCashiers(state, cashiers) {
            state.cashiers = cashiers
        },
        updateCashier(state, cashier) {
            state.cashier = cashier
        }
    },
    state: {
        cashiers: {
            models: [],
            totalItems: 0
        },
        cashier: {
            id: 0,
            name: '',
            balances: []
        }
    },
    getters: {
        getCashiers(state) {
            return state.cashiers.models
        },
        getCashier(state) {
            return state.cashier
        }
    }
}
