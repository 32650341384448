<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | Euroferr` : `Euroferr` }}</template>
    </metainfo>
    <MainLayout/>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import MainLayout from "@/layouts/MainLayout.vue";
import {useMeta} from "vue-meta";

export default {
    name: 'App',
    components: {MainLayout},
    setup () {
        useMeta({
            title: '',
            htmlAttrs: { lang: 'en', amp: true }
        })
    },
    computed: {
        ...mapGetters(['isAuthorized', 'getRefreshToken'])
    },
    methods: {
        ...mapActions(['fetchAboutMe', 'refreshToken']),
        ...mapMutations(['clearTokens'])
    },
    mounted() {
        if (this.isAuthorized) {
            this.fetchAboutMe()
                .catch(() => {
                    this.refreshToken(this.getRefreshToken)
                        .then(() => {
                            this.fetchAboutMe()
                        })
                        .catch(() => {
                            this.clearTokens()
                            this.$router.push('/login')
                        })
                })
        }
    }
}
</script>

<style>
a {
    color: inherit;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.select-none {
    user-select: none;
}

.link {
    color: dodgerblue;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #3F51B5;
        height: 200px;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #E8EAF6;
    }
}
</style>
