import getRequest from "@/plugins/requests/getRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
import formattedDateUtil from "@/mixins/formattedDate";
import postRequest from "@/plugins/requests/postRequest";

const toast = useToast()
let currentDate = new Date();
currentDate.setHours(currentDate.getHours() + 5);

export default {
    actions: {
        fetchCurrencyRate(context) {
            return getRequest('/last_currency_rate', 'updateCurrencyRate', context)
        },
        fetchCurrencyRateByDate(context, date) {
            const formattedDate = formattedDateUtil.methods.formatDate(date)

            return getRequest('/currency_rates/by_date?date=' + date, 'updateCurrencyRateByDate', context)
                .catch((error) => {
                    if (error.response.status === 400) {
                        toast.error(i18n.global.t('errorMessages.sentIncorrectDateFormat'), {
                            timeout: 2500,
                        });
                    } else if (error.response.status === 404) {
                        toast.error(i18n.global.t('errorMessages.usdRateNotFoundForThisDate', {date: formattedDate.toString()}), {
                            timeout: 3000,
                        });

                        let data = {
                            user: null,
                            usdToUzs: 0,
                            rubToUzs: 0,
                            usdToRub: 0,
                            createdAt: new Date(date).toISOString(),
                            date: new Date(date).toISOString()
                        }

                        context.commit('updateCurrencyRateByDate', data)
                    }
                })
        },
        pushCurrencyRate(context, data) {
            return postRequest('/currency_rates', data, 'updateCurrencyRateByDate', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 2000,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        toast.error(i18n.global.t('errorMessages.sentIncorrectDateFormat'), {
                            timeout: 2500,
                        });
                    } else if (error.response.status === 403) {
                        toast.error(i18n.global.t('errorMessages.cannotEnterRateForFuture'), {
                            timeout: 3000,
                        });
                    }
                })

        }
    },
    mutations: {
        updateCurrencyRate(state, currencyRate) {
            state.currencyRate = currencyRate
        },
        updateCurrencyRateByDate(state, currencyRateByDate) {
            state.currencyRateByDate = currencyRateByDate
        },
    },
    state: {
        currencyRate: {
            usdToUzs: 0,
            rubToUzs: 0,
            usdToRub: 0,
            createdAt: currentDate.toISOString(),
            date: currentDate.toISOString()
        },
        currencyRateByDate: {
            user: null,
            usdToUzs: 0,
            rubToUzs: 0,
            usdToRub: 0,
            createdAt: currentDate.toISOString(),
            date: currentDate.toISOString()
        },
    },
    getters: {
        getCurrencyRate(state) {
            return state.currencyRate
        },
        getCurrencyRateByDate(state) {
            return state.currencyRateByDate
        },
    }
}
