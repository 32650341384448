import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchReports(context, filterProps = {}) {
            let reportUrl = '';

            if (filterProps.periodFrom) {
                reportUrl += '&period-from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                reportUrl += '&period-to=' + filterProps.periodTo;
            }

            if (filterProps.providers.length || filterProps.customers.length) {
                reportUrl += '&partners=' + filterProps.providers.concat(filterProps.customers);
            }

            return getRequests(
                `/reports?page=${filterProps.forExcel ? filterProps.pageForExcel : filterProps.page}&items-per-page=${filterProps.forExcel ? filterProps.itemsPerPageForExcel : filterProps.itemsPerPage}&show=${filterProps.show}${reportUrl}`,
                filterProps.forExcel ? 'updateReportsForExcel' : 'updateReports',
                context
            )
        }
    },
    mutations: {
        updateReports(state, reports) {
            state.reports = reports
        },
        updateReportsForExcel(state, reportsForExcel) {
            state.reportsForExcel = reportsForExcel
        }
    },
    state: {
        reports: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        },
        reportsForExcel: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        }
    },
    getters: {
        getReports(state) {
            return state.reports
        },
        getReportsForExcel(state) {
            return state.reportsForExcel
        }
    }
}
