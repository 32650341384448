import getRequests from "@/plugins/requests/getRequests";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest";

const toast = useToast()

export default {
    actions: {
        fetchWidths(context) {
            return getRequests('/widths', 'updateWidths', context)
        },
        fetchDeletedWidths(context) {
            return getRequests('/widths-deleted', 'updateDeletedWidths', context)
        },
        deleteWidths(context, widths) {
            return postRequest('/widths/delete', widths, 'updateWidth', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreWidths(context, widths) {
            return postRequest('/widths/restore', widths, 'updateWidth', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
        pushWidth(context, width) {
            return postRequest('/widths', width, 'updateWidth', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
    },
    mutations: {
        updateWidths(state, widths) {
            state.widths = widths
        },
        updateDeletedWidths(state, deletedWidths) {
            state.deletedWidths = deletedWidths
        },
        updateWidth() {},
    },
    state: {
        widths: {
            models: [],
            totalItems: 0
        },
        deletedWidths: {
            models: [],
            totalItems: 0
        },
    },
    getters: {
        getWidths(state) {
            return state.widths.models
        },
        getDeletedWidths(state) {
            return state.deletedWidths.models
        },
    }
}