import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchProductNames(context, filterProps = {}) {
            let productNameUrl = '';

            if (filterProps.name) {
                productNameUrl = '?name=' + filterProps.name;
            }

            if (filterProps.cyrilName) {
                productNameUrl += '&cyrilName=' + filterProps.cyrilName;
            }

            return getRequests('/product_names' + productNameUrl, 'updateProductNames', context)
        },
        fetchDeletedProductNames(context) {
            return getRequests('/product_names/deleted', 'updateDeletedProductNames', context)
        },
        pushProductName(context, productName) {
            return postRequest('/product_names', productName, 'updateProductName', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteProductNames(context, productNames) {
            return postRequest('/product_names/delete', productNames, 'updateDeletedProductNames', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreProductNames(context, productNames) {
            return postRequest('/product_names/restore', productNames, 'updateProductName', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
    },

    mutations: {
        updateProductNames(state, productNames) {
            state.productNames = productNames
        },
        updateDeletedProductNames(state, deletedProductNames) {
            state.deletedProductNames = deletedProductNames
        },
        updateProductName() {}
    },

    state: {
        productNames: {
            models: [],
            totalItems: 0
        },
        deletedProductNames: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getProductNames(state) {
            return state.productNames.models
        },
        getProductNamesCount(state) {
            return state.productNames.totalItems
        },
        getDeletedProductNames(state) {
            return state.deletedProductNames.models
        }
    }
}