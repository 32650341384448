import getRequests from "@/plugins/requests/getRequests";
import deleteRequest from "@/plugins/requests/deleteRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
import postRequest from "@/plugins/requests/postRequest";

const toast = useToast()

export default {
    actions: {
        fetchProductGeneralData(context) {
            return getRequests('/products/general', 'updateProductGeneralData', context)
        },
        fetchAllProducts(context, filterProps = {}) {
            let productUrl = '';

            if (filterProps.productNames.length) {
                productUrl += '&product-names=' + filterProps.productNames;
            }

            if (filterProps.periodFrom) {
                productUrl += '&period-from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                productUrl += '&period-to=' + filterProps.periodTo;
            }

            if (filterProps.providers.length) {
                productUrl += '&providers=' + filterProps.providers;
            }

            if (filterProps.factories.length) {
                productUrl += '&factories=' + filterProps.factories;
            }

            if (filterProps.heights.length) {
                productUrl += '&heights=' + filterProps.heights;
            }

            if (filterProps.widths.length) {
                productUrl += '&widths=' + filterProps.widths;
            }

            if (filterProps.colors.length) {
                productUrl += '&colors=' + filterProps.colors;
            }

            if (filterProps.fromKg) {
                productUrl += '&kg-from=' + filterProps.fromKg;
            }

            if (filterProps.toKg) {
                productUrl += '&kg-to=' + filterProps.toKg;
            }

            if (filterProps.typeCoatings.length) {
                productUrl += '&type-coating=' + filterProps.typeCoatings;
            }

            if (filterProps.warehouses.length) {
                productUrl += '&warehouses=' + filterProps.warehouses;
            }

            if (filterProps.sorts.length) {
                productUrl += '&sorts=' + filterProps.sorts;
            }

            if (filterProps.fromPrice) {
                productUrl += '&price-from=' + filterProps.fromPrice;
            }

            if (filterProps.toPrice) {
                productUrl += '&price-to=' + filterProps.toPrice;
            }

            if (filterProps.invoiceId) {
                productUrl += '&invoice-id=' + filterProps.invoiceId;
            }

            if (filterProps.comment) {
                productUrl += '&comment=' + filterProps.comment;
            }

            if (filterProps.comment) {
                productUrl += '&comment=' + filterProps.comment;
            }

            return getRequests(
                `/products/all?page=${filterProps.forExcel ? filterProps.pageForExcel : filterProps.page}&items-per-page=${filterProps.forExcel ? filterProps.itemsPerPageForExcel : filterProps.itemsPerPage}&status=${filterProps.productStatus}&warehouse=${filterProps.warehouse}&order-value=${filterProps.orderBy.prop}&is-asc=${filterProps.orderBy.isAsc}${productUrl}`,
                filterProps.forExcel ? 'updateAllProductsForExcel' : 'updateAllProducts',
                context
            )
        },
        fetchAllActiveProducts(context, filterProps = {}) {
            let productUrl = '';

            if (filterProps.productNameId) {
                productUrl += '&product-name-id=' + filterProps.productNameId;
            }

            if (filterProps.providerId) {
                productUrl += '&provider-id=' + filterProps.providerId;
            }

            if (filterProps.factoryId) {
                productUrl += '&factory-id=' + filterProps.factoryId;
            }

            if (filterProps.colorId) {
                productUrl += '&color-id=' + filterProps.colorId;
            }

            if (filterProps.height) {
                productUrl += '&height=' + filterProps.height;
            }

            if (filterProps.width) {
                productUrl += '&width=' + filterProps.width;
            }

            if (filterProps.byKg) {
                productUrl += '&by-kg=' + filterProps.byKg;
            }

            return getRequests(`/products?page=${filterProps.page}&items-per-page=${filterProps.itemsPerPage}${productUrl}`, 'updateProducts', context)
        },
        fetchActiveProductsByInvoiceId(context, data = {}) {
            return getRequests('/products/income_invoice/' + data.invoiceId + '?status=' + data.status, 'updateProducts', context)
        },
        fetchExpensedProducts(context, data) {
            return getRequests('/products/expense_invoice/' + data.invoiceId + '?status=' + data.status, 'updateExpensedProducts', context)
        },
        fetchTransferredProducts(context, data) {
            return getRequests('/products/transfer_invoice/' + data.invoiceId, 'updateTransferredProducts', context)
        },
        fetchModifiedProducts(context, data = {}) {
            return getRequests('/products/modified_history/' + data.productId + '?type-id=' + data.typeId, 'updateModifiedProducts', context)
        },
        fetchReturnedProducts(context, invoiceId) {
            return getRequests('/products/returned/' + invoiceId, 'updateProducts', context)
        },
        fetchDefectiveProducts(context, filterProps = {}) {
            let productUrl = '';

            if (filterProps.productNameId) {
                productUrl += '&product-name-id=' + filterProps.productNameId;
            }

            if (filterProps.factoryId) {
                productUrl += '&factory-id=' + filterProps.factoryId;
            }

            if (filterProps.customerId) {
                productUrl += '&customer-id=' + filterProps.customerId;
            }

            return getRequests(
                `/products/defective?page=${filterProps.page}&items-per-page=${filterProps.itemsPerPage}${productUrl}`,
                'updateDefectiveProducts',
                context
            )
        },
        deleteProduct(context, productId) {
            return deleteRequest('/products/' + productId)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.productDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreProduct(context, data) {
            return postRequest('/products/restore', data, 'updateProduct', context)
                .then(() => {
                    toast.success(i18n.global.t('warehouse.productRestored'), {
                        timeout: 3000,
                    });
                })
        },
        returnProduct(context, data) {
            return postRequest('/products/return', data, 'updateProduct', context)
                .then(() => {
                    toast.success(i18n.global.t('warehouse.productSuccessReturned'), {
                        timeout: 3000,
                    });
                })
        },
        putProductComment(context, data) {
            return postRequest('/products/update-comment', data, 'updateProduct', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        fetchSalesTraffic(context, filterProps = {}) {
            return getRequests(
                `/products/sales_traffic?status=${filterProps.intervalStatus}&monthValue=${filterProps.monthValue}&yearValue=${filterProps.yearValue}`,
                'updateSalesTraffic',
                context
            )
        },
        fetchTopOrOutNomenclatures(context, filterProps = {}) {
            let url = '';

            if (filterProps.from) {
                url += '&from=' + filterProps.from;
            }

            if (filterProps.to) {
                url += '&to=' + filterProps.to;
            }

            return getRequests(
                `/products/top10?is-asc=${filterProps.isAsc}${url}`,
                filterProps.isAsc ? 'updateOutNomenclatures' : 'updateTopNomenclatures',
                context
            )
        },
        fetchProductBalances(context, data) {
            return getRequests('/products/balance?mode=' + data, 'updateBalances', context)
        }
    },
    mutations: {
        updateProductGeneralData(state, generalData) {
            state.generalData = generalData
        },
        updateProducts(state, products) {
            state.products = products
        },
        updateAllProducts(state, allProducts) {
            state.allProducts = allProducts
        },
        updateAllProductsForExcel(state, allProductsForExcel) {
            state.allProductsForExcel = allProductsForExcel
        },
        updateExpensedProducts(state, expensedProducts) {
            state.expensedProducts = expensedProducts
        },
        updateTransferredProducts(state, transferredProducts) {
            state.transferredProducts = transferredProducts
        },
        updateModifiedProducts(state, modifiedProducts) {
            state.modifiedProducts = modifiedProducts
        },
        updateDefectiveProducts(state, defectiveProducts) {
            state.defectiveProducts = defectiveProducts
        },
        updateSalesTraffic(state, salesTraffic) {
            state.salesTraffic = salesTraffic
        },
        updateOutNomenclatures(state, outNomenclatures) {
            state.outNomenclatures = outNomenclatures
        },
        updateTopNomenclatures(state, topNomenclatures) {
            state.topNomenclatures = topNomenclatures
        },
        updateBalances(state, balances) {
            state.balances = balances
        },
        updateProduct() {}
    },
    state: {
        defectiveProducts: {
            models: {
                products: [],
                sums: {
                    uzs: 0,
                    usd: 0,
                }
            },
            totalItems: 0,
            pagesCount: 0
        },
        products: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        },
        allProducts: {
            models: {
                products: [],
                totalWeight: 0,
            },
            totalItems: 0,
            pagesCount: 0
        },
        allProductsForExcel: {
            models: {
                products: [],
                totalWeight: 0,
            },
            totalItems: 0,
            pagesCount: 0
        },
        generalData: {
            models: [],
            totalItems: 0,
        },
        expensedProducts: {
            models: [],
            totalItems: 0,
        },
        transferredProducts: {
            models: [],
            totalItems: 0,
        },
        modifiedProducts: {
            models: [],
            totalItems: 0,
        },
        salesTraffic: {
            models: [],
        },
        outNomenclatures: {
            models: [],
        },
        topNomenclatures: {
            models: [],
        },
        balances: {
            models: [],
        }
    },
    getters: {
        getActiveProducts(state) {
            return state.products.models
        },
        getCountActiveProducts(state) {
            return state.products.totalItems
        },
        getPagesCountActiveProducts(state) {
            return state.products.pagesCount
        },
        getModifiedProducts(state) {
            return state.modifiedProducts.models
        },
        getExpensedProducts(state) {
            return state.expensedProducts
        },
        getTransferredProducts(state) {
            return state.transferredProducts
        },
        getReturnedProducts(state) {
            return state.products
        },
        getAllProducts(state) {
            return  state.allProducts
        },
        getAllProductsForExcel(state) {
            return  state.allProductsForExcel.models.products
        },
        getDefectiveProducts(state) {
            return state.defectiveProducts
        },
        getProductGeneralData(state) {
            return state.generalData.models
        },
        getSalesTraffic(state) {
            return state.salesTraffic.models
        },
        getOutNomenclatures(state) {
            return state.outNomenclatures.models
        },
        getTopNomenclatures(state) {
            return state.topNomenclatures.models
        },
        getProductBalances(state) {
            return state.balances.models
        }
    }
}
