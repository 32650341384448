<template>
    <VApp>
        <v-card class="rounded rounded-md">
            <!--   HEADER START     -->
            <v-app-bar
                density="comfortable"
                :color="'#fff'"
                :elevation="2"
            >
                <template v-slot:prepend>
                    <v-btn @click.stop="drawer = !drawer" v-if="$route.path !== '/login'" icon>
                        <v-icon color="#35336a" size="large">mdi-apps</v-icon>
                    </v-btn>
                </template>

                <div class="d-flex align-center nav_position" v-if="this.$route.path !== '/login'">
                    <VBtn @click="$router.back()" color="#35336A" size="small" variant="text">
                        <VIcon class="text-h5" size="x-large">mdi-arrow-left-thick</VIcon>
                    </VBtn>
                    <VBtn @click="this.$router.push('/')" color="#35336A" size="small" variant="text">
                        <VIcon class="text-h5" size="x-large">mdi-home</VIcon>
                    </VBtn>
                    <VBtn @click="$router.forward()" color="#35336A" size="small" variant="text">
                        <VIcon class="text-h5" size="x-large">mdi-arrow-right-thick</VIcon>
                    </VBtn>
                </div>

                <LanguageSwitcherComponent class="mr-2"/>
            </v-app-bar>
            <!--   HEADER END     -->

            <!--   SIDE NAVBAR START     -->
            <v-navigation-drawer
                v-model="drawer"
                temporary
                color="#35336a"
            >
                <v-list density="compact" nav>
                    <VListItem>
                        <router-link to="/">
                            <div v-if="$route.path !== '/login'" class="company_logo cursor-pointer">
                                <img src="/images/logo_header2.png" alt="">
                            </div>
                        </router-link>
                    </VListItem>

                    <VDivider :thickness="1" class="my-2"></VDivider>

                    <VListItem>
                        <div class="d-flex align-center">
                            <div class="mr-4 avatar">
                                <img :src="getUser.person.image ? url + getUser.person.image.contentUrl : '/images/avatar.png'" alt="avatar">
                            </div>
                            <div>
                                <div class="menu_font text-capitalize">{{ getUser.person.givenName }}</div>
                                <div class="role_font" v-if="getUser.roles.length">{{ $t(`roles.${getUser.roles[0]}`) }}</div>
                            </div>
                        </div>
                    </VListItem>

                    <VDivider :thickness="1" class="my-2"></VDivider>

                    <router-link to="/">
                        <v-list-item value="dashboard" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-chart-arc</VIcon> </template>
                            {{ $t('menu.dashboard') }}
                        </v-list-item>
                    </router-link>

                    <router-link v-if="combinationTwo" to="/accounting/1">
                        <v-list-item value="finance" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-bank-transfer</VIcon> </template>
                            {{ $t('menu.finance') }}
                        </v-list-item>
                    </router-link>

                    <router-link v-if="combinationThree" to="/warehouses">
                        <v-list-item value="warehouse" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-warehouse</VIcon> </template>
                            {{ $t('menu.warehouse') }}
                        </v-list-item>
                    </router-link>

                    <router-link v-if="combinationSix" to="/partners/1">
                        <v-list-item value="reports" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-text-box-multiple-outline</VIcon> </template>
                            {{ $t('menu.reports') }}
                        </v-list-item>
                    </router-link>

                    <router-link v-if="combinationFive" to="/employees">
                        <v-list-item value="employees" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-account-group-outline</VIcon> </template>
                            {{ $t('menu.employees') }}
                        </v-list-item>
                    </router-link>

                    <router-link to="/employee">
                        <v-list-item value="profile" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-card-account-details-star-outline</VIcon> </template>
                            {{ $t('menu.profile') }}
                        </v-list-item>
                    </router-link>

                    <router-link v-if="combinationSix" to="/logs/1">
                        <v-list-item value="logs" class="menu_font my-2">
                            <template v-slot:prepend> <VIcon size="large">mdi-view-list-outline</VIcon> </template>
                            {{ $t('logs.logs') }}
                        </v-list-item>
                    </router-link>

                    <VDivider :thickness="1" class="my-2"></VDivider>

                    <v-list-item @click="logout()" class="menu_font my-2">
                        <template v-slot:prepend> <VIcon size="large">mdi-logout</VIcon> </template>
                        {{ $t('menu.logout') }}
                    </v-list-item>

                </v-list>
            </v-navigation-drawer>
            <!--   SIDE NAVBAR END     -->

            <!--   CONTENT START     -->
            <v-main class="pa-4 pa-md-12 mt-14 mt-md-8" :style="`min-height: calc(100vh - ${smAndDown ? '56' : '32'}px);`">
                <div class="mx-auto">
                    <router-view/>
                </div>
            </v-main>
            <!--   CONTENT END     -->
        </v-card>
    </VApp>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import LanguageSwitcherComponent from "@/components/LanguageSwitcherComponent.vue";
import {useDisplay} from "vuetify";
import accessMixin from "@/mixins/accessMixin";

export default {
    name: "MainLayout",
    components: {LanguageSwitcherComponent},
    mixins: [accessMixin],
    setup() {
        const { smAndDown } = useDisplay()
        return { smAndDown }
    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL,
            drawer: false
        }
    },
    methods: {
        ...mapMutations(['clearTokens']),
        logout() {
            this.clearTokens()
            this.$router.push('/login')
        },
        goToPage(route) {
            this.drawer = !this.drawer
            this.$router.push(route)
        }
    },
    computed: {
        ...mapGetters(['getUser'])
    }
}
</script>

<style scoped>
.nav_position {
    position: absolute;
    left: calc(50% - 75px);
}

.company_logo {
    height: 46px;
}

.company_logo img {
    border-radius: 10%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.avatar {
    aspect-ratio: 1;
    height: 50px;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10%;
    object-position: center;
}

.menu_font {
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
}

.role_font {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}
</style>