import access from "@/mixins/functions/access";

export default {
    computed: {
        combinationOne: () => access.combinationOne(),
        combinationTwo: () => access.combinationTwo(),
        combinationThree: () => access.combinationThree(),
        combinationFour: () => access.combinationFour(),
        combinationFive: () => access.combinationFive(),
        combinationSix: () => access.combinationSix(),
        combinationSeven: () => access.combinationSeven(),
        combinationEight: () => access.combinationEight(),
        combinationNine: () => access.combinationNine(),
    },
}
