import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchColors(context) {
            return getRequests('/colors', 'updateColors', context)
        },
        fetchDeletedColors(context) {
            return getRequests('/colors-deleted', 'updateDeletedColors', context)
        },
        pushColor(context, color) {
            return postRequest('/colors', color, 'updateColor', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteColors(context, colors) {
            return postRequest('/colors/delete', colors, 'updateDeletedColors', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreColors(context, colors) {
            return postRequest('/colors/restore', colors, 'updateColor', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
    },

    mutations: {
        updateColors(state, colors) {
            state.colors = colors
        },
        updateDeletedColors(state, deletedColors) {
            state.deletedColors = deletedColors
        },
        updateColor() {}
    },

    state: {
        colors: {
            models: [],
            totalItems: 0
        },
        deletedColors: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getColors(state) {
            return state.colors.models
        },
        getDeletedColors(state) {
            return state.deletedColors.models
        }
    }
}