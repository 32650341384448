import axios from "axios";

export default {
    actions: {
        fetchLogs(context, filterProps = {}) {
            let logUrl = '';

            if (filterProps.types) {
                logUrl += '&types=' + filterProps.types;
            }

            if (filterProps.departments) {
                logUrl += '&departments=' + filterProps.departments;
            }

            if (filterProps.users) {
                logUrl += '&users=' + filterProps.users;
            }

            if (filterProps.periodFrom) {
                logUrl += '&from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                logUrl += '&to=' + filterProps.periodTo;
            }

            return new Promise((resolve, reject) => {
                axios.get('/logs?page=' + filterProps.page + logUrl)
                    .then((response) => {
                        let logs = {
                            models: response.data.logs['hydra:member'],
                            totalItems: response.data.totalItems,
                            pagesCount: response.data.pagesCount
                        }

                        context.commit('updateLogs', logs)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateLogs(state, logs) {
            state.logs = logs
        }
    },
    state: {
        logs: {
            models: [],
            totalItems: 0,
            pagesCount: 0
        }
    },
    getters: {
        getLogs(state) {
            return state.logs.models
        },
        getLogsCount(state) {
            return state.logs
        }
    }
}
