import {createApp} from 'vue'
import App from './App.vue'
import store from '@/plugins/vuex/store'
import router from "@/plugins/vuex/router"
import i18n from "@/locales/i18n"
import Toast from "vue-toastification"
import {options} from './plugins/toastOptions'
import VueApexCharts from "vue3-apexcharts"
import { createMetaManager } from 'vue-meta'
import "vue-toastification/dist/index.css"
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

createApp(App)
    .use(VueApexCharts)
    .use(createMetaManager())
    .use(store)
    .use(router)
    .use(Toast, options)
    .use(i18n)
    .use(vuetify)
    .mount('#app')
