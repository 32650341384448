import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchFirms(context, filterProps = {}) {
            let firmUrl = '';

            if (filterProps.name) {
                firmUrl = '&name=' + filterProps.name;
            }

            return getRequests('/firms?page=' + filterProps.page + firmUrl, 'updateFirms', context)
        },
        fetchDeletedFirms(context, filterProps = {}) {
            let firmUrl = '';

            if (filterProps.name) {
                firmUrl = '&name=' + filterProps.name;
            }

            return getRequests('/firms/deleted?page=' + filterProps.page + firmUrl, 'updateDeletedFirms', context)
        },
        pushFirm(context, firm) {
            return postRequest('/firms', firm, 'updateFirm', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteFirms(context, firms) {
            return postRequest('/firms/delete', firms, 'updateFirm', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreFirms(context, firms) {
            return postRequest('/firms/restore', firms, 'updateFirm', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
    },
    mutations: {
        updateFirms(state, firms) {
            state.firms = firms
        },
        updateDeletedFirms(state, deletedFirms) {
            state.deletedFirms = deletedFirms
        },
        updateFirm() {},
    },
    state: {
        firms: {
            models: [],
            totalItems: 0
        },
        deletedFirms: {
            models: [],
            totalItems: 0
        }
    },
    getters: {
        getFirms(state) {
            return state.firms.models
        },
        getFirmsCount(state) {
            return state.firms.totalItems
        },
        getDeletedFirms(state) {
            return state.deletedFirms.models
        },
        getDeletedFirmsCount(state) {
            return state.deletedFirms.totalItems
        }
    }
}
