import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchCountries(context) {
            return getRequests('/countries', 'updateCountries', context)
        },
        fetchDeletedCountries(context) {
            return getRequests('/countries/deleted', 'updateDeletedCountries', context)
        },
        pushCountry(context, country) {
            return postRequest('/countries', country, 'updateCountry', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteCountries(context, countries) {
            return postRequest('/countries/delete', countries, 'updateDeletedCountries', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreCountries(context, countries) {
            return postRequest('/countries/restore', countries, 'updateCountry', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
    },

    mutations: {
        updateCountries(state, countries) {
            state.countries = countries
        },
        updateDeletedCountries(state, deletedCountries) {
            state.deletedCountries = deletedCountries
        },
        updateCountry() {}
    },

    state: {
        countries: {
            models: [],
            totalItems: 0
        },
        deletedCountries: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getCountries(state) {
            return state.countries.models
        },
        getDeletedCountries(state) {
            return state.deletedCountries.models
        }
    }
}