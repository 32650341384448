import getRequests from "@/plugins/requests/getRequests";
import postRequest from "@/plugins/requests/postRequest";
import i18n from "@/locales/i18n";
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchWarehouses(context) {
            return getRequests('/warehouses', 'updateWarehouses', context)
        },
        fetchDeletedWarehouses(context) {
            return getRequests('/warehouses/deleted', 'updateDeletedWarehouses', context)
        },
        pushWarehouse(context, warehouse) {
            return postRequest('/warehouses', warehouse, 'updateWarehouse', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteWarehouses(context, warehouses) {
            return postRequest('/warehouses/delete', warehouses, 'updateDeletedWarehouses', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.elementsDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreWarehouses(context, warehouses) {
            return postRequest('/warehouses/restore', warehouses, 'updateWarehouse', context)
                .then(() => {
                    toast.info(i18n.global.t('warehouse.elementsRestored'), {
                        timeout: 3000,
                    });
                })
        },
        fetchWarehouseGeneralData(context) {
            return getRequests('/warehouses/general-data', 'updateWarehouseGeneralData', context)
        }
    },

    mutations: {
        updateWarehouses(state, warehouses) {
            state.warehouses = warehouses
        },
        updateDeletedWarehouses(state, deletedWarehouses) {
            state.deletedWarehouses = deletedWarehouses
        },
        updateWarehouseGeneralData(state, warehouseGeneralData) {
            state.warehouseGeneralData = warehouseGeneralData
        },
        updateWarehouse() {}
    },

    state: {
        warehouses: {
            models: [],
            totalItems: 0
        },
        deletedWarehouses: {
            models: [],
            totalItems: 0
        },
        warehouseGeneralData: {
            models: [],
            totalItems: 0
        }
    },

    getters: {
        getWarehouses(state) {
            return state.warehouses.models
        },
        getDeletedWarehouses(state) {
            return state.deletedWarehouses.models
        },
        getWarehouseGeneralData(state) {
            return state.warehouseGeneralData.models
        }
    }
}