import postRequest from "@/plugins/requests/postRequest";
import getRequests from "@/plugins/requests/getRequests";
import getRequest from "@/plugins/requests/getRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
    actions: {
        fetchInvoices(context, filterProps = {}) {
            let invoiceUrl = '';

            if (filterProps.invoiceId) {
                invoiceUrl += '&invoiceId=' + filterProps.invoiceId;
            }

            if (filterProps.invoiceType) {
                invoiceUrl += '&invoiceType=' + filterProps.invoiceType;
            }

            if (filterProps.periodFrom) {
                invoiceUrl += '&from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                invoiceUrl += '&to=' + filterProps.periodTo;
            }

            if (filterProps.customers.length || filterProps.providers.length) {
                const partners = filterProps.providers.concat(filterProps.customers);

                invoiceUrl += '&partners=' + partners;
            }

            if (filterProps.warehouses.length) {
                invoiceUrl += '&warehouses=' + filterProps.warehouses;
            }

            if (filterProps.productNames.length) {
                invoiceUrl += '&productNames=' + filterProps.productNames;
            }

            if (filterProps.isDeletedInvoices) {
                invoiceUrl += '&isDeletedInvoices=' + filterProps.isDeletedInvoices;
            }

            if (filterProps.invoiceIdOrderByAsc) {
                invoiceUrl += '&invoice-id-order=' + filterProps.invoiceIdOrderByAsc;
            }

            if (filterProps.inputtedAtOrderByAsc) {
                invoiceUrl += '&inputted-at-order=' + filterProps.inputtedAtOrderByAsc;
            }

            if (filterProps.partnerOrderByAsc) {
                invoiceUrl += '&partner-order=' + filterProps.partnerOrderByAsc;
            }

            return getRequests('/invoices?page=' + filterProps.page + invoiceUrl, 'updateInvoices', context)
        },
        fetchInvoice(context, invoiceId) {
            return getRequest('/invoices/' + invoiceId, 'updateInvoice', context)
        },
        fetchExpInvoiceByProductId(context, productId) {
            return getRequest('/invoice_exp_by_product?id=' + productId, 'updateInvoice', context)
        },
        pushInvoice(context, data) {
            return postRequest('/invoices/income-add', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushExpenseInvoice(context, data) {
            return postRequest('/invoices/expense-add', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        editInvoice(context, data) {
            return postRequest('/invoices/income-edit', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        editExpenseInvoice(context, data) {
            return postRequest('/invoices/expense-edit', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushTransferInvoice(context, data) {
            return postRequest('/invoices/transfer-add', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        editTransferInvoice(context, data) {
            return postRequest('/invoices/transfer-edit', data, 'updateInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        deleteInvoice(context, data) {
            return postRequest('/invoices/delete', data, 'updateRestoredInvoice', context)
                .then(() => {
                    toast.error(i18n.global.t('warehouse.invoiceDeleted'), {
                        timeout: 3000,
                    });
                })
        },
        restoreInvoice(context, data) {
            return postRequest('/invoices/restore', data, 'updateRestoredInvoice', context)
                .then(() => {
                    toast.success(i18n.global.t('warehouse.invoiceRestored'), {
                        timeout: 3000,
                    });
                })
        }
    },

    mutations: {
        updateInvoice(state, invoice) {
            state.invoice = invoice
        },
        updateInvoices(state, invoices) {
            state.invoices = invoices
        },
        updateRestoredInvoice() {}
    },

    state: {
        invoice: {
            inputtedAt: '',
            partner: {
                name: '',
                currency: {
                    name: ''
                }
            },
            toWhere: {
                name: '',
                isKeeper: false
            },
            user: {
                person: {
                    givenName: '',
                    familyName: ''
                }
            },
            comment: ''
        },
        invoices: {
            models: {
                invoices: [],
                productsCount: 0,
                productsWeight: 0,
                productsUzsPrice: 0,
                productsUsdPrice: 0,
            },
            totalItems: 0,
            pagesCount: 0
        }
    },

    getters: {
        getInvoices(state) {
            return state.invoices
        },
        getInvoice(state) {
            return state.invoice
        },
    }
}