import store from "@/plugins/vuex/store";

const combinationOne = ['ROLE_ADMIN']
const combinationTwo = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_ACCOUNTANT']
const combinationThree = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_ACCOUNTANT', 'ROLE_WAREHOUSE_MANAGER', 'ROLE_MANAGER']
const combinationFour = ['ROLE_ADMIN', 'ROLE_WAREHOUSE_MANAGER', 'ROLE_MANAGER']
const combinationFive = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR']
const combinationSix = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_ACCOUNTANT', 'ROLE_MANAGER']
const combinationSeven = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_MANAGER']
const combinationEight = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_ACCOUNTANT', 'ROLE_WAREHOUSE_MANAGER']
const combinationNine = ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_DIRECTOR', 'ROLE_WAREHOUSE_MANAGER']

export default {
    checkExistence(combinations) {
    return store.getters.getUserRoles.some(role => combinations.includes(role));
    },

    //  Combination 1
    combinationOne() {
        return this.checkExistence(combinationOne)
    },

    //  Combination 2
    combinationTwo() {
        return this.checkExistence(combinationTwo)
    },

    //  Combination 3
    combinationThree() {
        return this.checkExistence(combinationThree)
    },

    //  Combination 4
    combinationFour() {
        return this.checkExistence(combinationFour)
    },

    //  Combination 5
    combinationFive() {
        return this.checkExistence(combinationFive)
    },

    //  Combination 6
    combinationSix() {
        return this.checkExistence(combinationSix)
    },

    //  Combination 7
    combinationSeven() {
        return this.checkExistence(combinationSeven)
    },

    //  Combination 8
    combinationEight() {
        return this.checkExistence(combinationEight)
    },

    //  Combination 9
    combinationNine() {
        return this.checkExistence(combinationNine)
    },
}