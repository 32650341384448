import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchCompanyExpenses(context) {
            return getRequests('/company_expenses', 'updateCompanyExpenses', context)
        }
    },

    mutations: {
        updateCompanyExpenses(state, companyExpenses) {
            state.companyExpenses = companyExpenses
        }
    },

    state: {
        companyExpenses: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getCompanyExpenses(state) {
            return state.companyExpenses.models
        }
    }
}