import getRequest from "@/plugins/requests/getRequest";

export default {
    actions: {
        fetchSmsCount(context, filterProps = {}) {
            return getRequest(
                `/getSmsCount?monthValue=${filterProps.monthValue}&yearValue=${filterProps.yearValue}&status=${filterProps.status}`,
                'updateSmsCount',
                context
            )
        }
    },
    mutations: {
        updateSmsCount(state, smsCount) {
            state.smsCount = smsCount
        }
    },
    state: {
        smsCount: {
            count: 0
        }
    },
    getters: {
        getSmsCount(state) {
            return state.smsCount.count
        }
    }
}
