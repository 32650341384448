import {createStore} from "vuex"
import user from "@/plugins/vuex/modules/user"
import balance from "@/plugins/vuex/modules/balance"
import currency from "@/plugins/vuex/modules/currency"
import currencyRate from "@/plugins/vuex/modules/currencyRate"
import height from "@/plugins/vuex/modules/height"
import factory from "@/plugins/vuex/modules/factory"
import firm from "@/plugins/vuex/modules/firm"
import country from "@/plugins/vuex/modules/country"
import color from "@/plugins/vuex/modules/color"
import companyExpense from "@/plugins/vuex/modules/companyExpense"
import cashier from "@/plugins/vuex/modules/cashier"
import partner from "@/plugins/vuex/modules/partner"
import productName from "@/plugins/vuex/modules/productName"
import product from "@/plugins/vuex/modules/product"
import warehouse from "@/plugins/vuex/modules/warehouse"
import invoice from "@/plugins/vuex/modules/invoice"
import role from "@/plugins/vuex/modules/role"
import report from "@/plugins/vuex/modules/report"
import smsCount from "@/plugins/vuex/modules/smsCount"
import sort from "@/plugins/vuex/modules/sort"
import typeCoating from "@/plugins/vuex/modules/typeCoating"
import mediaObject from "@/plugins/vuex/modules/mediaObject"
import finance from "@/plugins/vuex/modules/finance"
import financeCategory from "@/plugins/vuex/modules/officeExpense"
import log from "@/plugins/vuex/modules/log"
import width from "@/plugins/vuex/modules/width"

export default createStore({
    modules: {
        balance,
        color,
        companyExpense,
        cashier,
        country,
        currency,
        currencyRate,
        factory,
        firm,
        height,
        invoice,
        mediaObject,
        user,
        partner,
        product,
        productName,
        role,
        report,
        smsCount,
        sort,
        typeCoating,
        warehouse,
        finance,
        financeCategory,
        log,
        width
    }
})
