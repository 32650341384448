import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchOfficeExpenses(context) {
            return getRequests('/office_expenses', 'updateOfficeExpenses', context)
        }
    },

    mutations: {
        updateOfficeExpenses(state, officeExpenses) {
            state.officeExpenses = officeExpenses
        }
    },

    state: {
        officeExpenses: {
            models: [],
            totalItems: 0
        },
    },

    getters: {
        getOfficeExpenses(state) {
            return state.officeExpenses.models
        }
    }
}